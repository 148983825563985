<template>
  <div class="animated fadeIn">
    <table-custom
      ref="dataTable"
      mode="server"
      :name="`${$customTable.getCustomTableName(module || $route.meta.module)}`"
      :loading="dataTable.isLoading"
      :data="dataTable.dataSet"
      :options="dataTable.options"
      @row-select="onRowSelect"
      @view-item="viewItem"
      @edit-item="editItem"
      @delete-item="deleteItem"
      @server-filter="getData(appliedFilters, $event)"
      @pagination="getData(appliedFilters, $event)"
      @sort="getData(appliedFilters, $event)"
    >
      <div slot="afterFilter">
        <b-row v-if="dataTable.visible && this.showAddButton">
          <b-col>
            <b-button-group>
              <b-button
                variant="outline-dark"
                title="Add Account"
                @click="addItem()"
              >
                <font-awesome-icon icon="plus" /> Create
              </b-button>

              <b-button
                variant="outline-dark"
                title="Merge accounts"
                :disabled="has2SelectedRows ? false : true"
                @click="mergeAccounts()"
              >
                <font-awesome-icon icon="compress-arrows-alt" /> Merge
              </b-button>

              <b-button
                variant="outline-dark"
                title="Delete accounts"
                :disabled="hasSelectedRows ? false : true"
                @click="deleteAccounts()"
              >
                <font-awesome-icon icon="trash" /> Delete
              </b-button>
            </b-button-group>
          </b-col>
        </b-row>
      </div>

      <div slot="custom-actions" slot-scope="props">
        <div class="btn-group">
          <span class="btn btn-primary btn-sm" @click="viewItem(props.row.ID)">
            <font-awesome-icon icon="eye" />
          </span>
          <span class="btn btn-success btn-sm" @click="editItem(props.row.ID)">
            <font-awesome-icon icon="pencil-alt"
          /></span>
          <span class="btn btn-danger btn-sm" @click="deleteItem(props.row.ID)">
            <font-awesome-icon icon="trash"
          /></span>
          <button
            :class="[
              props.row['FilesCount'] !== '0'
                ? 'btn btn-secondary btn-sm'
                : 'btn btn-outline-secondary btn-sm'
            ]"
            @click="toggleFiles(props.row['ID'])"
          >
            <font-awesome-icon icon="download" />
          </button>
        </div>
      </div>
      <span slot-scope="props" slot="child_row">
        <files-container
          ref="files"
          :module-id="MODULES.Accounts.id"
          :entity-id="props.row['ID']"
        />
      </span>
    </table-custom>

    <b-modal
      ref="account-merge-modal"
      centered
      scrollable
      size="xl"
      title="Accounts merge"
      hide-footer
      no-close-on-esc
      no-close-on-backdrop
    >
      <b-container fluid class="ma-0 pa-0" style="height: 75vh;">
        <AccountsMergeForm :accounts="selectedRows" @close="closeMergeModal" />
      </b-container>
      <!--
      <div class="form-row d-flex  justify-content-end">
        <b-button variant="outline-dark" class="m-1" @click="closeMergeModal()">
          <font-awesome-icon icon="times" /> Close
        </b-button>
      </div>
      -->
    </b-modal>
  </div>
</template>

<script>
import FilesContainer from "@/components/FilesContainer";
import TableCustom from "@/components/TableCustom";
import AccountsMergeForm from "./AccountsMergeForm/index.js";

import { MODULES } from "@/shared/constants";

export default {
  name: "AccountsTable",
  props: {
    name: {
      type: String,
      default: ""
    },
    selectable: {
      type: Boolean,
      default: false
    },
    showAddButton: {
      type: Boolean,
      default: true
    },
    hideSettingsBar: {
      type: Boolean,
      default: false
    },
    perPage: {
      type: Number,
      default: 50
    },
    autoload: {
      type: Boolean,
      default: false
    },
    showActions: {
      type: Boolean,
      default: true
    },
    filter: {
      type: Array,
      default: () => {
        return [];
      }
    },
    module: {
      type: Object,
      default: undefined
    }
  },
  components: {
    FilesContainer,
    TableCustom,
    AccountsMergeForm
  },
  data: function() {
    return {
      MODULES: MODULES,
      rawData: {},
      appliedFilters: [],
      selectedRows: [],
      dataTable: {
        visible: true,
        isLoading: false,
        options: {
          columns: [
            "ID",
            "Account Name",
            "Chain",
            "Phone",
            "Email",
            "Website",
            "Category",
            "Assigned To",
            "Global",
            "Country",
            "City",
            "State",
            "Created",
            "Modified",
            "Last Activity Date",
            "Last Activity",
            "Actions"
          ],
          filterable: [
            "ID",
            "Account Name",
            "Chain",
            "Phone",
            "Email",
            "Website",
            "Category",
            "Assigned To",
            "Country",
            "State",
            "City",
            "Created",
            "Modified",
            "Last Activity Date",
            "Last Activity"
          ],
          uniqueKey: "ID",
          showChildRowToggler: false,
          filterByColumn: true,
          perPage: this.perPage,
          perPageValues: [],
          showCustomActions: true,
          showChildRows: true,
          selectableRows: this.selectable,
          hideSettingsBar: this.hideSettingsBar
        },

        dataSet: [],
        onRowClick: function() {},
        totalRecords: 0
      }
    };
  },
  computed: {
    hasSelectedRows() {
      return this.selectedRows.length > 0;
    },
    has2SelectedRows() {
      return this.selectedRows.length > 1;
    }
  },
  created() {
    if (this.showActions === false) {
      this.dataTable.options.columns = this.dataTable.options.columns.filter(
        c => c !== "Actions"
      );
    }
  },
  mounted() {
    if (this.autoload) this.getData({});
  },
  methods: {
    getSelectedRows() {
      return this.$refs.dataTable.getSelectedRows();
    },
    onRowSelect(e) {
      this.selectedRows = this.$refs.dataTable.getSelectedRows();

      this.$emit("row-select", e);
    },

    async getData(payload, options) {
      let self = this;

      this.dataTable.isLoading = true;

      //this.dataTable.dataSet = [];

      this.appliedFilters = payload;

      payload.serverOptions = options || self.$refs.dataTable.serverOptions;

      //if filter has been passed through props [ex. Duplicate search table] then override column filters
      if (this.filter.length > 0) {
        payload.serverOptions.filter = this.filter;
        payload.serverOptions.strict_filter = "true";
      }

      //required for prevNext functionaltiy
      this.$customTable.saveAppliedFilters(
        this.$constants.MODULES.Accounts,
        this.appliedFilters
      );
      //console.log(payload);
      return this.$api
        .post("accounts", payload)
        .then(response => {
          self.dataTable.isLoading = false;

          self.$emit("loaded", response.length);

          self.dataTable.dataSet = response.data;

          if (response.count === 0) return;

          if (self.$refs.dataTable)
            self.$refs.dataTable.setTotalPaginationRows(response.count);

          //20210131 #316 global search
          //self.$emit("loaded", self.dataTable.dataSet.length)
        })
        .catch(error => {
          console.log(error);
          self.dataTable.isLoading = false;
          self.$form.msgBoxOk("Error occured");
        });
    },
    toggleFiles(id) {
      this.$refs.dataTable.toggleChildRow(id);
    },
    addItem: function() {
      this.$router.push({
        name: "Account submission",
        params: {
          action: "create"
        }
      });
    },

    viewItem: function(id) {
      this.$router.push({
        name: "Account submission",
        params: {
          action: "view",
          id: id
        }
      });
    },
    editItem: function(id) {
      this.$router.push({
        name: "Account submission",
        params: {
          action: "edit",
          id: id
        }
      });
    },
    deleteItem: async function(id) {
      let account = this.dataTable.dataSet.find(item => item.ID === id);

      let confirm = await this.$form.showConfirmation(
        `Account #${account.ID} will be deleted. Do you want to proceed?`
      );

      if (!confirm) return;

      let self = this;

      //self.dataTable.dataSet = self.dataTable.dataSet.filter(i => i["ID"] !== account.ID)

      this.$api
        .delete(`accounts/${account.ID}`)
        .then(response => {
          self.$form.makeToastInfo(response.message);

          self.getData(self.appliedFilters);
        })
        .catch(response => {
          console.log(response);

          self.getData(self.appliedFilters);

          self.$form.makeToastError(response.message);
        });
    },
    mergeAccounts() {
      this.$store.dispatch("accountsMerge/saveAccounts", this.selectedRows);

      this.$refs["account-merge-modal"].show();
    },
    closeMergeModal() {
      this.$refs["account-merge-modal"].hide();
    },
    async deleteAccounts() {
      const selectedAccounts = this.selectedRows
        .map(a => a["Account Name"])
        .join();

      let confirm = await this.$form.showConfirmation(
        `Following accounts will be deleted: <p>${selectedAccounts}.</p>Do you want to proceed?`
      );

      if (!confirm) return;

      let self = this;

      this.dataTable.isLoading = true;

      let payload = this.selectedRows.map(a => a["ID"]);

      this.$api
        .put(`accounts/bulk/delete`, payload)
        .then(response => {
          self.$form.makeToastInfo(response.message);

          self.getData(self.appliedFilters);

          this.selectedRows = [];
        })
        .catch(response => {
          console.log(response);

          self.getData(self.appliedFilters);

          self.$form.makeToastError(response.message);
        });
    }
  }
};
</script>

<style scoped>
::v-deep .modal-body {
  overflow-y: visible !important;
}
</style>
