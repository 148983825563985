var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "animated fadeIn"
  }, [_c('div', {
    staticClass: "column_wrapper"
  }, [_c('b-table', {
    ref: "merge-table",
    attrs: {
      "id": "merge-table",
      "fields": _vm.stagesTable.fields,
      "items": _vm.stagesTable.items,
      "responsive": "sm",
      "selectable": "",
      "select-mode": "multi",
      "selected-variant": ""
    },
    on: {
      "row-selected": _vm.onRowSelected,
      "row-clicked": _vm.onRowClick,
      "head-clicked": _vm.onHeadClicked
    },
    scopedSlots: _vm._u([{
      key: "cell(selected)",
      fn: function fn(row) {
        return [_vm._v(" " + _vm._s(row.selected) + " "), _c('b-form-checkbox', {
          attrs: {
            "id": "".concat(row.item.id, "-checkbox-selected"),
            "disabled": true
          },
          model: {
            value: row.rowSelected,
            callback: function callback($$v) {
              _vm.$set(row, "rowSelected", $$v);
            },
            expression: "row.rowSelected"
          }
        })];
      }
    }, {
      key: "cell(estimation)",
      fn: function fn(row) {
        return [_vm._v(" " + _vm._s(row.item.estimation) + " ")];
      }
    }, {
      key: "cell(status)",
      fn: function fn(row) {
        return [row.item.isLoading ? _c('b-spinner', {
          attrs: {
            "small": "",
            "type": "grow"
          }
        }) : _vm._e(), !row.item.isLoading ? _c('span', [_vm._v(_vm._s(row.item.status))]) : _vm._e()];
      }
    }])
  })], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }