<template>
  <div class="animated fadeIn">
    <b-row>
      <b-col>
        <inline-select
          id="select:base-account-2"
          label="Base account"
          :width="30"
          :readonly="false"
          :value="baseAccount"
          :options="getBaseAccountOptions()"
          :allow-empty="false"
          :mode="$constants.FORMCONTROLMODE.EDIT"
          :required="true"
          @changed="onBaseAccountChange"
        />
      </b-col>
    </b-row>
    <div class="column_wrapper">
      <template v-for="(propertyName, index) in fields">
        <div class="cell" :key="`${uid}-${index}`">
          <inline-select
            :id="propertyName"
            :value="data[propertyName]"
            :label="propertyName"
            :readonly="false"
            :options="getElementOptions(propertyName)"
            :allow-empty="false"
            :mode="$constants.FORMCONTROLMODE.EDIT"
            :required="false"
            :width="15"
            @changed="onSelectChange"
          />
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import InlineSelect from "@/components/InlineSelectMultiple";
import { mapGetters, mapActions } from "vuex";
const fields = [
  "FEIN",
  "Authorized WH Keeper Number",
  "Billing Contact",
  "Billing Email",
  "Tax Warehouse Name",
  "Tax Warehouse Number",
  "Ship City",
  "Ship Country",
  "Ship PO Box",
  "Ship Postal Code",
  "Ship State",
  "Ship Street",
  "Shipping Contact",
  "Shipping Email"
];
export default {
  name: "AccountMergeStep2",
  props: {
    clickedNext: {
      type: Boolean,
      default: false
    },
    currentStep: {
      type: Object,
      default: () => {}
    }
  },
  components: {
    InlineSelect
  },
  data: function() {
    return {
      accounts: [],
      fields: fields,
      baseAccount: {},
      data: {},
      fieldsMapping: {}
    };
  },
  computed: {
    ...mapGetters("accountsMerge", [
      "getAccounts",
      "getBaseAccount",
      "getStep2"
    ])
  },
  created() {},
  mounted() {
    this.initialize();
  },

  methods: {
    ...mapActions("accountsMerge", [
      "saveStep2",
      "saveBaseAccount",
      "updateAccount"
    ]),
    async initialize() {
      this.baseAccount = this.getBaseAccount;

      this.accounts = this.getAccounts;

      let fetchDataPromises = [];

      //fetch full account data and save to storage
      this.accounts.forEach(acc => {
        let p = this.$api.get(`accounts/${acc["ID"]}`).then(response => {
          this.updateAccount({
            id: acc["ID"],
            data: response
          });
        });

        fetchDataPromises.push(p);
      });

      await Promise.all(fetchDataPromises);

      Object.assign(this.fieldsMapping, this.getStep2);
    },
    getBaseAccountOptions() {
      return this.accounts.map(i => ({
        id: i["ID"],
        label: `${i["ID"]}: ${i["Account Name"]}`
      }));
    },
    getElementOptions(propertyName) {
      return this.accounts.map(i => ({
        id: i["ID"],
        label: `${i["ID"]}:
        ${i[propertyName]}`
      }));
    },
    onSelectChange(id, value) {
      this.data[id] = value;

      this.fieldsMapping[id] = value.id;
    },
    setBaseAccount(value) {
      this.baseAccount = value;

      this.updateMapping(value);
    },
    onBaseAccountChange(id, value) {
      if (JSON.stringify(this.baseAccount) === JSON.stringify(value)) return;

      this.saveBaseAccount(value);

      this.baseAccount = value;

      this.updateMapping(value);

      this.$emit("base-account-changed", value);
    },
    updateMapping(value) {
      let account = this.accounts.find(a => a["ID"] === value.id);

      this.fields.forEach(field => {
        this.data[field] = {
          id: account[field],
          label: account[field]
        };

        this.fieldsMapping[field] = value.id;
      });
    },
    saveState() {
      this.saveStep2(this.fieldsMapping);
    },
    isValid() {
      if (!this.baseAccount.id) {
        this.$form.makeToastError("Please select base account");
        return false;
      }
      return true;
    }
  },
  watch: {}
};
</script>

<style scoped>
.column_wrapper {
  height: 50vh;
  display: flex;
  flex-flow: column wrap;
}

.cell {
  padding: 0.5em;
}

.form-group {
  margin-bottom: 0.2rem !important;
}
</style>
