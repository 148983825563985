var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "animated fadeIn"
  }, [_c('table-custom', {
    ref: "dataTable",
    attrs: {
      "mode": "server",
      "name": "".concat(_vm.$customTable.getCustomTableName(_vm.module || _vm.$route.meta.module)),
      "loading": _vm.dataTable.isLoading,
      "data": _vm.dataTable.dataSet,
      "options": _vm.dataTable.options
    },
    on: {
      "row-select": _vm.onRowSelect,
      "view-item": _vm.viewItem,
      "edit-item": _vm.editItem,
      "delete-item": _vm.deleteItem,
      "server-filter": function serverFilter($event) {
        return _vm.getData(_vm.appliedFilters, $event);
      },
      "pagination": function pagination($event) {
        return _vm.getData(_vm.appliedFilters, $event);
      },
      "sort": function sort($event) {
        return _vm.getData(_vm.appliedFilters, $event);
      }
    },
    scopedSlots: _vm._u([{
      key: "custom-actions",
      fn: function fn(props) {
        return _c('div', {}, [_c('div', {
          staticClass: "btn-group"
        }, [_c('span', {
          staticClass: "btn btn-primary btn-sm",
          on: {
            "click": function click($event) {
              return _vm.viewItem(props.row.ID);
            }
          }
        }, [_c('font-awesome-icon', {
          attrs: {
            "icon": "eye"
          }
        })], 1), _c('span', {
          staticClass: "btn btn-success btn-sm",
          on: {
            "click": function click($event) {
              return _vm.editItem(props.row.ID);
            }
          }
        }, [_c('font-awesome-icon', {
          attrs: {
            "icon": "pencil-alt"
          }
        })], 1), _c('span', {
          staticClass: "btn btn-danger btn-sm",
          on: {
            "click": function click($event) {
              return _vm.deleteItem(props.row.ID);
            }
          }
        }, [_c('font-awesome-icon', {
          attrs: {
            "icon": "trash"
          }
        })], 1), _c('button', {
          class: [props.row['FilesCount'] !== '0' ? 'btn btn-secondary btn-sm' : 'btn btn-outline-secondary btn-sm'],
          on: {
            "click": function click($event) {
              return _vm.toggleFiles(props.row['ID']);
            }
          }
        }, [_c('font-awesome-icon', {
          attrs: {
            "icon": "download"
          }
        })], 1)])]);
      }
    }, {
      key: "child_row",
      fn: function fn(props) {
        return _c('span', {}, [_c('files-container', {
          ref: "files",
          attrs: {
            "module-id": _vm.MODULES.Accounts.id,
            "entity-id": props.row['ID']
          }
        })], 1);
      }
    }])
  }, [_c('div', {
    attrs: {
      "slot": "afterFilter"
    },
    slot: "afterFilter"
  }, [_vm.dataTable.visible && this.showAddButton ? _c('b-row', [_c('b-col', [_c('b-button-group', [_c('b-button', {
    attrs: {
      "variant": "outline-dark",
      "title": "Add Account"
    },
    on: {
      "click": function click($event) {
        return _vm.addItem();
      }
    }
  }, [_c('font-awesome-icon', {
    attrs: {
      "icon": "plus"
    }
  }), _vm._v(" Create ")], 1), _c('b-button', {
    attrs: {
      "variant": "outline-dark",
      "title": "Merge accounts",
      "disabled": _vm.has2SelectedRows ? false : true
    },
    on: {
      "click": function click($event) {
        return _vm.mergeAccounts();
      }
    }
  }, [_c('font-awesome-icon', {
    attrs: {
      "icon": "compress-arrows-alt"
    }
  }), _vm._v(" Merge ")], 1), _c('b-button', {
    attrs: {
      "variant": "outline-dark",
      "title": "Delete accounts",
      "disabled": _vm.hasSelectedRows ? false : true
    },
    on: {
      "click": function click($event) {
        return _vm.deleteAccounts();
      }
    }
  }, [_c('font-awesome-icon', {
    attrs: {
      "icon": "trash"
    }
  }), _vm._v(" Delete ")], 1)], 1)], 1)], 1) : _vm._e()], 1)]), _c('b-modal', {
    ref: "account-merge-modal",
    attrs: {
      "centered": "",
      "scrollable": "",
      "size": "xl",
      "title": "Accounts merge",
      "hide-footer": "",
      "no-close-on-esc": "",
      "no-close-on-backdrop": ""
    }
  }, [_c('b-container', {
    staticClass: "ma-0 pa-0",
    staticStyle: {
      "height": "75vh"
    },
    attrs: {
      "fluid": ""
    }
  }, [_c('AccountsMergeForm', {
    attrs: {
      "accounts": _vm.selectedRows
    },
    on: {
      "close": _vm.closeMergeModal
    }
  })], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }