<template>
  <div class="animated fadeIn">
    <div class="column_wrapper">
      <b-table
        id="merge-table"
        ref="merge-table"
        :fields="stagesTable.fields"
        :items="stagesTable.items"
        responsive="sm"
        selectable
        select-mode="multi"
        @row-selected="onRowSelected"
        selected-variant=""
        @row-clicked="onRowClick"
        @head-clicked="onHeadClicked"
      >
        <template #cell(selected)="row">
          {{ row.selected }}
          <b-form-checkbox
            :id="`${row.item.id}-checkbox-selected`"
            v-model="row.rowSelected"
            :disabled="true"
          />
        </template>
        <!--

      <template #cell(amount)="row">
        <b-spinner v-if="stagesTable.isStatisticsLoading" small type="grow" />
        <span v-if="!stagesTable.isStatisticsLoading">{{
          row.item.amount
        }}</span>
      </template>

      <template #cell(stage)="row">
        {{ row.item.state }}
      </template>
-->
        <template #cell(estimation)="row">
          {{ row.item.estimation }}
        </template>

        <template #cell(status)="row">
          <b-spinner v-if="row.item.isLoading" small type="grow" />
          <span v-if="!row.item.isLoading">{{ row.item.status }}</span>
        </template>
      </b-table>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: "AccountMergeStep4",
  props: {
    clickedNext: {
      type: Boolean,
      default: false
    },
    currentStep: {
      type: Object,
      default: () => {}
    }
  },
  components: {},
  data: function() {
    return {
      isInterrupted: false,
      accounts: [],
      stagesTable: {
        isStatisticsLoading: false,
        state: {},
        fields: [
          {
            key: "selected",
            label: "#",
            tdClass: "cell"
          },
          {
            key: "stage",
            label: "Stage",
            tdClass: "cell"
          },
          /*
          {
            key: "estimation",
            label: "Estimation",
            tdClass: "cell"
          },
*/
          {
            key: "status",
            label: "Status",
            tdClass: "cell"
          }
        ],
        items: [
          {
            id: 0,
            selected: false,
            api_url: "accounts/merge/fields",
            stage: "Fields",
            amount: 0,
            method: this.mergeFields,
            description: "Accounts fields",
            status: "",
            isLoading: false
          },
          {
            id: 1,
            selected: false,
            api_url: "accounts/merge/contacts",
            stage: "Contacts",
            amount: 0,
            target_user: {},
            description: "Contacts linked with accounts",
            status: "",
            isLoading: false
          },
          {
            id: 2,
            selected: false,
            api_url: "accounts/merge/activities",
            stage: "Activities",
            amount: 0,
            target_user: {},
            description: "Activities linked with accounts",
            status: "",
            isLoading: false
          },
          {
            id: 3,
            selected: false,
            api_url: "accounts/merge/invoices",
            stage: "Invoices",
            amount: 0,
            target_user: {},
            description: "",
            status: "",
            isLoading: false
          },
          {
            id: 4,
            selected: false,
            api_url: "accounts/merge/wh-invoices",
            stage: "Warehouse Invoices",
            amount: 0,
            target_user: {},
            description: "",
            status: "",
            isLoading: false
          },

          {
            id: 5,
            selected: false,
            api_url: "accounts/merge/files",
            stage: "Files",
            amount: 0,
            target_user: {},
            description: "",
            status: "",
            isLoading: false
          },
          {
            id: 6,
            selected: false,
            api_url: "accounts/merge/markets",
            stage: "Markets",
            amount: 0,
            target_user: {},
            description: "",
            status: "",
            isLoading: false
          },

          {
            id: 7,
            selected: false,
            api_url: "accounts/merge/incentives",
            stage: "Incentives",
            amount: 0,
            target_user: {},
            description: "",
            status: "",
            isLoading: false
          },
          {
            id: 8,
            selected: false,
            api_url: "accounts/merge/receipts",
            stage: "Receipts",
            amount: 0,
            target_user: {},
            description: "",
            status: "",
            isLoading: false
          },
          {
            id: 9,
            selected: false,
            api_url: "accounts/merge/projects",
            stage: "Projects",
            amount: 0,
            target_user: {},
            description: "",
            status: "",
            isLoading: false
          },
          {
            id: 10,
            selected: false,
            api_url: "accounts/merge/tasks",
            stage: "Tasks",
            amount: 0,
            target_user: {},
            description: "",
            status: "",
            isLoading: false
          },
          {
            id: 11,
            selected: false,
            api_url: "accounts/merge/samples",
            stage: "Samples",
            amount: 0,
            target_user: {},
            description: "",
            status: "",
            isLoading: false
          },
          {
            id: 12,
            selected: false,
            api_url: "accounts/merge/disable-merged",
            stage: "Disable merged accounts",
            amount: 0,
            target_user: {},
            description: "",
            status: "",
            isLoading: false
          }
        ],
        selectedStages: []
      },
      mapping: {}
    };
  },
  computed: {
    ...mapGetters("accountsMerge", [
      "getAccounts",
      "getBaseAccount",
      "getStep1",
      "getStep2",
      "getStep3"
    ])
  },
  created() {},
  mounted() {
    this.initialize();
  },
  updated() {},
  methods: {
    ...mapActions("accountsMerge", ["saveStep4"]),
    initialize() {
      this.baseAccount = this.getBaseAccount;
      this.accounts = this.getAccounts;
      this.selectAllRows();
    },
    onRowSelected(items) {
      this.stagesTable.selectedStages = items;
    },
    selectAllRows() {
      this.$refs["merge-table"].selectAllRows();
    },
    deselectAllRows() {
      this.$refs["merge-table"].clearSelected();
    },
    onHeadClicked(key) {
      if (key === "selected") this.selectAllRows();
    },
    onRowSelect() {},
    stopMerge() {
      this.isInterrupted = true;
    },
    async mergeAccounts() {
      this.$emit("merge-started");

      this.isInterrupted = false;

      this.baseAccount = this.getBaseAccount;

      this.compileMapping();

      for (let i = 0; i < this.stagesTable.selectedStages.length; i++) {
        if (this.isInterrupted) {
          break;
        }

        let item = this.stagesTable.selectedStages[i];

        item.isLoading = true;

        let accounts = this.accounts
          .filter(a => a["ID"] !== this.baseAccount.id)
          .map(a => a["ID"]);
        let mapping =
          this.mapping[item.stage] && this.mapping[item.stage].length
            ? this.mapping[item.stage].filter(i => i.id !== this.baseAccount.id)
            : this.mapping[item.stage];

        let payload = {
          base_account: this.baseAccount.id,
          accounts: accounts,
          mapping: mapping
        };

        //console.log("mergeAccounts", item.api_url, this.accounts, payload);

        let response = await this.$api.post(item.api_url, payload);
        item.status = response.message;

        item.isLoading = false;
      }

      if (!this.isInterrupted) this.$emit("merge-completed");
      if (this.isInterrupted) this.$emit("merge-stopped");
    },
    compileMapping() {
      this.mapping.Fields = { ...this.getStep1, ...this.getStep2 };

      let step3State = this.getStep3;

      for (let p in step3State) {
        this.mapping[p] = step3State[p];
      }
    },
    async mergeFields() {
      await this.$helpers.delay(100);
      return true;
    },

    onRowClick() {},
    saveState() {
      this.saveStep4(this.state);
    }
  },

  watch: {}
};
</script>

<style scoped>
.column_wrapper {
  height: 58vh;
  display: flex;
  flex-flow: column wrap;
}

::v-deep .cell {
  padding: 0.3em !important;
}
</style>
