<template>
  <div class="animated fadeIn">
    <b-row>
      <b-col>
        <inline-select
          id="select:base-account-3"
          label="Base account"
          :width="30"
          :readonly="false"
          :value="baseAccount"
          :options="getBaseAccountOptions()"
          :allow-empty="false"
          :mode="$constants.FORMCONTROLMODE.EDIT"
          :required="true"
          @changed="onBaseAccountChange"
        />
      </b-col>
    </b-row>

    <div class="column_wrapper">
      <div
        class="cell"
        v-for="(item, index) in entities"
        :key="`${uid}-${index}`"
      >
        <inline-select
          :id="item"
          :value="data[item]"
          :label="item"
          :readonly="false"
          :allow-empty="false"
          :mode="$constants.FORMCONTROLMODE.EDIT"
          :required="false"
          :options="getElementOptions()"
          :width="15"
          :multiple="true"
          @changed="onSelectChange"
        />
      </div>
    </div>
  </div>
</template>

<script>
import InlineSelect from "@/components/InlineSelectMultiple";
import { mapGetters, mapActions } from "vuex";
const entities = [
  "Contacts",
  "Activities",
  "Invoices",
  "Files",
  "Markets",
  "Incentives",
  "Receipts",
  "Projects",
  "Tasks",
  "Samples",
  "Warehouse Invoices"
];
export default {
  name: "AccountMergeStep3",
  props: {
    clickedNext: {
      type: Boolean,
      default: false
    },
    currentStep: {
      type: Object,
      default: () => {}
    }
  },
  components: {
    InlineSelect
  },
  data: function() {
    return {
      accounts: [],
      entities: entities,
      baseAccount: {},
      fieldsMapping: {},
      data: {}
    };
  },
  computed: {
    ...mapGetters("accountsMerge", [
      "getAccounts",
      "getBaseAccount",
      "getStep3"
    ])
  },
  created() {},
  mounted() {
    this.initialize();
  },
  methods: {
    ...mapActions("accountsMerge", [
      "saveStep3",
      "saveBaseAccount",
      "updateAccount"
    ]),
    initialize() {
      this.accounts = this.getAccounts;
    },
    getBaseAccountOptions() {
      return this.accounts.map(i => ({
        id: i["ID"],
        label: `${i["ID"]}: ${i["Account Name"]}`
      }));
    },

    getElementOptions() {
      return this.accounts.map(i => ({
        id: i["ID"],
        label: `${i["ID"]}: ${i["Account Name"]}`
      }));
    },
    setBaseAccount(value) {
      this.baseAccount = value;

      this.updateMapping(value);
    },
    onBaseAccountChange(id, value) {
      if (JSON.stringify(this.baseAccount) === JSON.stringify(value)) return;

      this.saveBaseAccount(value);

      this.baseAccount = value;

      this.updateMapping(value);

      this.$emit("base-account-changed", value);
    },
    updateMapping() {
      const values = this.getBaseAccountOptions();
      entities.forEach(entity => {
        this.data[entity] = values;

        this.fieldsMapping[entity] = values;
      });
    },
    onSelectChange(id, value) {
      this.data[id] = value;

      this.fieldsMapping[id] = value;
    },
    saveState() {
      this.saveStep3(this.fieldsMapping);
    },
    isValid() {
      if (!this.baseAccount.id) {
        this.$form.makeToastError("Please select base account");
        return false;
      }
      return true;
    }
  },
  watch: {}
};
</script>

<style scoped>
.column_wrapper {
  height: 50vh;
  display: flex;
  flex-flow: column wrap;
}

.cell {
  padding: 0.5em;
}

.form-group {
  margin-bottom: 0.2rem !important;
}
</style>
