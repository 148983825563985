var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "animated fadeIn"
  }, [_c('b-row', [_c('b-col', [_c('inline-select', {
    attrs: {
      "id": "select:base-account",
      "label": "Base account",
      "width": 30,
      "readonly": false,
      "value": _vm.baseAccount,
      "options": _vm.getBaseAccountOptions(),
      "allow-empty": false,
      "mode": _vm.$constants.FORMCONTROLMODE.EDIT,
      "required": true
    },
    on: {
      "changed": _vm.onBaseAccountChange
    }
  })], 1)], 1), _c('div', {
    staticClass: "column_wrapper"
  }, [_vm._l(_vm.fields, function (propertyName, index) {
    return [_c('div', {
      key: "".concat(_vm.uid, "-").concat(index),
      staticClass: "cell"
    }, [_c('inline-select', {
      attrs: {
        "id": propertyName,
        "value": _vm.data[propertyName],
        "label": propertyName,
        "readonly": false,
        "options": _vm.getElementOptions(propertyName),
        "allow-empty": false,
        "mode": _vm.$constants.FORMCONTROLMODE.EDIT,
        "required": false,
        "width": 15
      },
      on: {
        "changed": _vm.onSelectChange
      }
    })], 1)];
  })], 2)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }