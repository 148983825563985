<template>
  <div class="animated fadeIn">
    <form-wizard
      title=""
      subtitle=""
      step-size="xs"
      color="#20a8d8"
      @on-change="onStepChange"
    >
      <tab-content
        title="Map base fields"
        icon="fa fa-list"
        :before-change="validateStep1"
      >
        <step-1 ref="step1" @base-account-changed="onBaseAccountChange" />
      </tab-content>
      <tab-content
        title="Map distributor fields"
        icon="fa fa-list"
        :before-change="validateStep2"
      >
        <step-2 ref="step2" @base-account-changed="onBaseAccountChange" />
      </tab-content>
      <tab-content
        title="Map related entities"
        icon="fa fa-cubes"
        :before-change="validateStep3"
      >
        <step-3 ref="step3" @base-account-changed="onBaseAccountChange" />
      </tab-content>
      <tab-content title="Merge data" icon="fa fa-compress">
        <step-4
          ref="step4"
          @merge-started="onMergeStart"
          @merge-completed="onMergeComplete"
          @merge-stopped="onMergeStop"
        />
      </tab-content>

      <template slot="footer" slot-scope="props">
        <div class="wizard-footer-left">
          <wizard-button
            v-if="props.activeTabIndex > 0 && !isMergeInProgress"
            @click.native="props.prevTab()"
            :style="props.fillButtonStyle"
            >Previous</wizard-button
          >
        </div>
        <div class="wizard-footer-right">
          <wizard-button
            v-if="!props.isLastStep && !isMergeInProgress"
            @click.native="props.nextTab()"
            class="wizard-footer-right"
            :style="props.fillButtonStyle"
            >Next</wizard-button
          >

          <wizard-button
            v-if="props.isLastStep && !isMergeInProgress"
            @click.native="startMerge()"
            class="wizard-footer-right finish-button ml-2"
            :style="props.fillButtonStyle"
            >Merge</wizard-button
          >

          <wizard-button
            v-if="props.isLastStep && isMergeInProgress"
            @click.native="stopMerge()"
            class="wizard-footer-right finish-button"
            :style="props.fillButtonStyle"
            >Stop</wizard-button
          >

          <wizard-button
            v-if="props.isLastStep && !isMergeInProgress"
            @click.native="close()"
            class="wizard-footer-right finish-button"
            :style="props.fillButtonStyle"
            >Close</wizard-button
          >
        </div>
      </template>
    </form-wizard>
  </div>
</template>

<script>
import { FormWizard, TabContent, WizardButton } from "vue-form-wizard";
import "vue-form-wizard/dist/vue-form-wizard.min.css";

import Step1 from "./Step1.vue";
import Step2 from "./Step2.vue";
import Step3 from "./Step3.vue";
import Step4 from "./Step4.vue";

export default {
  name: "AccountsMergeForm",
  props: {
    accounts: {
      type: Array,
      default: () => {
        return [];
      }
    }
  },
  components: {
    FormWizard,
    TabContent,
    WizardButton,
    Step1,
    Step2,
    Step3,
    Step4
  },
  data: function() {
    return {
      isMergeInProgress: false,
      isMergeCompleted: false,
      data: {
        account_name: {},
        phone: {},
        fax: {},
        email: {},
        website: {},
        chain: {},
        assigned_to: {},
        category: {},
        type: {}
      }
    };
  },
  created() {},
  mounted() {},
  methods: {
    startMerge() {
      this.$refs.step4.mergeAccounts();
    },
    stopMerge() {
      this.$refs.step4.stopMerge();
    },
    close() {
      this.$emit("close");
    },
    onStepChange(value) {
      this.isMergeCompleted = false;

      const step = this.$refs[`step${value + 1}`];

      if (step) step.saveState();
    },
    onMergeStart() {
      this.isMergeInProgress = true;
    },
    onMergeStop() {
      this.isMergeInProgress = false;
      this.isMergeCompleted = false;
    },
    onMergeComplete() {
      this.isMergeInProgress = false;
      this.isMergeCompleted = true;
    },

    async validateStep1() {
      let result = this.$refs.step1.isValid();
      return result;
    },
    async validateStep2() {
      let result = this.$refs.step2.isValid();
      return result;
    },
    async validateStep3() {
      let result = this.$refs.step3.isValid();
      return result;
    },
    onBaseAccountChange(value) {
      this.$refs.step1.setBaseAccount(value);
      this.$refs.step2.setBaseAccount(value);
      this.$refs.step3.setBaseAccount(value);
    }
  },
  watch: {}
};
</script>

<style scoped>
::v-deep .vue-form-wizard .wizard-header {
  padding: 0px !important;
}
</style>
