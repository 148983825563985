var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "animated fadeIn"
  }, [_c('form-wizard', {
    attrs: {
      "title": "",
      "subtitle": "",
      "step-size": "xs",
      "color": "#20a8d8"
    },
    on: {
      "on-change": _vm.onStepChange
    },
    scopedSlots: _vm._u([{
      key: "footer",
      fn: function fn(props) {
        return [_c('div', {
          staticClass: "wizard-footer-left"
        }, [props.activeTabIndex > 0 && !_vm.isMergeInProgress ? _c('wizard-button', {
          style: props.fillButtonStyle,
          nativeOn: {
            "click": function click($event) {
              return props.prevTab();
            }
          }
        }, [_vm._v("Previous")]) : _vm._e()], 1), _c('div', {
          staticClass: "wizard-footer-right"
        }, [!props.isLastStep && !_vm.isMergeInProgress ? _c('wizard-button', {
          staticClass: "wizard-footer-right",
          style: props.fillButtonStyle,
          nativeOn: {
            "click": function click($event) {
              return props.nextTab();
            }
          }
        }, [_vm._v("Next")]) : _vm._e(), props.isLastStep && !_vm.isMergeInProgress ? _c('wizard-button', {
          staticClass: "wizard-footer-right finish-button ml-2",
          style: props.fillButtonStyle,
          nativeOn: {
            "click": function click($event) {
              return _vm.startMerge();
            }
          }
        }, [_vm._v("Merge")]) : _vm._e(), props.isLastStep && _vm.isMergeInProgress ? _c('wizard-button', {
          staticClass: "wizard-footer-right finish-button",
          style: props.fillButtonStyle,
          nativeOn: {
            "click": function click($event) {
              return _vm.stopMerge();
            }
          }
        }, [_vm._v("Stop")]) : _vm._e(), props.isLastStep && !_vm.isMergeInProgress ? _c('wizard-button', {
          staticClass: "wizard-footer-right finish-button",
          style: props.fillButtonStyle,
          nativeOn: {
            "click": function click($event) {
              return _vm.close();
            }
          }
        }, [_vm._v("Close")]) : _vm._e()], 1)];
      }
    }])
  }, [_c('tab-content', {
    attrs: {
      "title": "Map base fields",
      "icon": "fa fa-list",
      "before-change": _vm.validateStep1
    }
  }, [_c('step-1', {
    ref: "step1",
    on: {
      "base-account-changed": _vm.onBaseAccountChange
    }
  })], 1), _c('tab-content', {
    attrs: {
      "title": "Map distributor fields",
      "icon": "fa fa-list",
      "before-change": _vm.validateStep2
    }
  }, [_c('step-2', {
    ref: "step2",
    on: {
      "base-account-changed": _vm.onBaseAccountChange
    }
  })], 1), _c('tab-content', {
    attrs: {
      "title": "Map related entities",
      "icon": "fa fa-cubes",
      "before-change": _vm.validateStep3
    }
  }, [_c('step-3', {
    ref: "step3",
    on: {
      "base-account-changed": _vm.onBaseAccountChange
    }
  })], 1), _c('tab-content', {
    attrs: {
      "title": "Merge data",
      "icon": "fa fa-compress"
    }
  }, [_c('step-4', {
    ref: "step4",
    on: {
      "merge-started": _vm.onMergeStart,
      "merge-completed": _vm.onMergeComplete,
      "merge-stopped": _vm.onMergeStop
    }
  })], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }