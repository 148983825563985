var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "animated fadeIn"
  }, [_c('b-row', [_c('b-col', [_c('inline-select', {
    attrs: {
      "id": "select:base-account-3",
      "label": "Base account",
      "width": 30,
      "readonly": false,
      "value": _vm.baseAccount,
      "options": _vm.getBaseAccountOptions(),
      "allow-empty": false,
      "mode": _vm.$constants.FORMCONTROLMODE.EDIT,
      "required": true
    },
    on: {
      "changed": _vm.onBaseAccountChange
    }
  })], 1)], 1), _c('div', {
    staticClass: "column_wrapper"
  }, _vm._l(_vm.entities, function (item, index) {
    return _c('div', {
      key: "".concat(_vm.uid, "-").concat(index),
      staticClass: "cell"
    }, [_c('inline-select', {
      attrs: {
        "id": item,
        "value": _vm.data[item],
        "label": item,
        "readonly": false,
        "allow-empty": false,
        "mode": _vm.$constants.FORMCONTROLMODE.EDIT,
        "required": false,
        "options": _vm.getElementOptions(),
        "width": 15,
        "multiple": true
      },
      on: {
        "changed": _vm.onSelectChange
      }
    })], 1);
  }), 0)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }